import Vue from "vue";
import VueRouter from "vue-router";
//import AuthGuard from "./utils/auth.guard";
import store from "@/store";
import { adminRoot, bricksRoot } from "./constants/config";

Vue.use(VueRouter);

let pausedResolve = null;
let pausedPromise = Promise.resolve();

function pause() {
  if (!pausedResolve) {
    pausedPromise = new Promise((resolve) => (pausedResolve = resolve));
  }
}

export function resume() {
  if (pausedResolve) {
    pausedResolve();
    pausedResolve = null;
  }
}

const routes = [
  {
    path: "/",
    redirect: `${bricksRoot}/default`,
    meta: {},
  },
  {
    path: "/user/recover_password",
    component: () => import("./views/user/RecoverPassword.vue"),
  },
  {
    path: "/status",
    component: () => import("./views/app/Status"),
  },
  {
    path: bricksRoot,
    component: () => import(/* webpackChunkName: "app" */ "./views/app/bricks"),
    redirect: `${bricksRoot}/default`,
    children: [
      {
        path: "default",
        component: () => import("./views/app/bricks/Homepage"),
        meta: { authOnly: true },
      },
      {
        path: "catalog",
        component: () => import("./views/app/bricks/Catalogue"),
        meta: { authOnly: true },
      },
      {
        path: "favorites",
        component: () => import("./views/app/bricks/FavouriteCards"),
      },
      {
        path: "video",
        component: () => import("./views/app/bricks/video"),
        redirect: `${bricksRoot}/video/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/video/Default"),
          },

          {
            path: "label-detection",
            component: () => import("./views/app/bricks/video/LabelDetection"),
          },
          {
            path: "text-detection",
            component: () => import("./views/app/bricks/video/TextDetection"),
          },
          {
            path: "shot-change-detection",
            component: () => import("./views/app/bricks/video/ShotChangeDetection"),
          },
          {
            path: "person-tracking",
            component: () => import("./views/app/bricks/video/PersonTracking"),
          },
          {
            path: "face-detection",
            component: () => import("./views/app/bricks/video/FaceDetection"),
          },
          {
            path: "explicit-content-detection",
            component: () => import("./views/app/bricks/video/ExplicitContent"),
          },
          {
            path: "logo-detection",
            component: () => import("./views/app/bricks/video/LogoDetection"),
          },
          {
            path: "object-tracking",
            component: () => import("./views/app/bricks/video/ObjectTracking"),
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "image",
        component: () => import("./views/app/bricks/image"),
        redirect: `${bricksRoot}/image/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/image/Default"),
          },
          {
            path: "ocr",
            component: () => import("./views/app/bricks/ocr/OCR.vue"),
          },
          {
            path: "explicit-content-detection",
            component: () => import("./views/app/bricks/image/ExplicitContentDetection.vue"),
          },
          {
            path: "ai-detection",
            component: () => import("./views/app/bricks/image/AiDetection.vue"),
          },
          {
            path: "object-detection",
            component: () => import("./views/app/bricks/image/ObjectDetection.vue"),
          },
          {
            path: "face-detection",
            component: () => import("./views/app/bricks/image/FaceDetection.vue"),
          },
          {
            path: "landmark-detection",
            component: () => import("./views/app/bricks/image/LandmarkDetection.vue"),
          },
          {
            path: "logo-detection",
            component: () => import("./views/app/bricks/image/LogoDetection.vue"),
          },
          {
            path: "anonymization",
            component: () => import("./views/app/bricks/image/Anonymization.vue"),
          },
          {
            path: "similarity-search",
            component: () => import("./views/app/bricks/image/SimilaritySearch.vue"),
          },
          {
            path: "generation",
            component: () => import("./views/app/bricks/image/Generation.vue"),
          },
          {
            path: "face-recognition",
            component: () => import("./views/app/bricks/image/FaceRecognition.vue"),
          },
          {
            path: "face-compare",
            component: () => import("./views/app/bricks/image/FaceCompare.vue"),
          },
          {
            path: "embeddings",
            component: () =>
              (window.location.href = "https://docs.edenai.co/reference/image_embeddings_create"),
          },
          {
            path: "question-answer",
            component: () => import("./views/app/bricks/image/QuestionAnswer.vue"),
          },
          {
            path: "background-removal",
            component: () => import("./views/app/bricks/image/BackgroundRemoval.vue"),
          },
          {
            path: "automl-classification",
            component: () => import("./views/app/bricks/image/automl-classification"),
            redirect: `${bricksRoot}/image/automl-classification/default`,
            children: [
              {
                path: "project/:id",
                component: () =>
                  import(
                    "@/views/app/bricks/image/automl-classification/AutomlClassificationProject.vue"
                  ),
              },
              {
                path: "default",
                component: () =>
                  import("./views/app/bricks/image/automl-classification/AutomlClassification.vue"),
              },
            ],
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "text",
        component: () => import("./views/app/bricks/text"),
        redirect: `${bricksRoot}/text/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/text/Default"),
          },
          {
            path: "entity_sentiment",
            component: () => import("./views/app/bricks/text/EntitySentiment.vue"),
          },
          {
            path: "named-entity-recognition",
            component: () => import("./views/app/bricks/text/NamedEntityRecognition.vue"),
          },
          {
            path: "custom-named-entity-recognition",
            component: () => import("./views/app/bricks/text/CustomNamedEntityRecognition.vue"),
          },
          {
            path: "custom-classification",
            component: () => import("./views/app/bricks/text/CustomTextClassification.vue"),
          },
          {
            path: "keyword-extraction",
            component: () => import("./views/app/bricks/text/KeywordExtraction.vue"),
          },
          {
            path: "sentiment-analysis",
            component: () => import("./views/app/bricks/text/SentimentAnalysis.vue"),
          },
          {
            path: "embeddings",
            component: () =>
              (window.location.href = "https://docs.edenai.co/reference/text_embeddings_create"),
          },
          {
            path: "syntax-analysis",
            component: () => import("./views/app/bricks/text/SyntaxAnalysis.vue"),
          },
          {
            path: "language-detection",
            component: () => import("./views/app/bricks/translation/LanguageDetection.vue"),
          },
          {
            path: "question-answer",
            component: () => import("./views/app/bricks/text/QuestionAnswer.vue"),
          },
          {
            path: "search",
            component: () => import("./views/app/bricks/text/Search.vue"),
          },
          {
            path: "summarization",
            component: () => import("./views/app/bricks/text/Summarization.vue"),
          },
          {
            path: "anonymization",
            component: () => import("./views/app/bricks/text/Anonymization.vue"),
          },
          {
            path: "topic-extraction",
            component: () => import("./views/app/bricks/text/TopicExtraction.vue"),
          },
          {
            path: "generation",
            component: () => import("./views/app/bricks/text/Generation.vue"),
          },
          {
            path: "code-generation",
            component: () => import("./views/app/bricks/text/CodeGeneration.vue"),
          },
          {
            path: "prompt-optimization",
            component: () => import("./views/app/bricks/text/PromptOptimization.vue"),
          },
          {
            path: "moderation",
            component: () => import("./views/app/bricks/text/Moderation.vue"),
          },
          {
            path: "spell-check",
            component: () => import("./views/app/bricks/text/SpellCheck.vue"),
          },
          {
            path: "chat",
            component: () => import("./views/app/bricks/text/Chat.vue"),
          },
          {
            path: "multimodal-chat",
            component: () => import("./views/app/bricks/multimodal/MultimodalChat.vue"),
          },
          {
            path: "ai-detection",
            component: () => import("./views/app/bricks/text/AiDetection.vue"),
          },
          {
            path: "plagia-detection",
            component: () => import("./views/app/bricks/text/PlagiaDetection.vue"),
          },
          {
            path: "emotion-detection",
            component: () => import("./views/app/bricks/text/EmotionDetection.vue"),
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "ocr",
        component: () => import("./views/app/bricks/ocr"),
        redirect: `${bricksRoot}/ocr/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/ocr/Default"),
          },
          {
            path: "ocr-invoice",
            component: () => import("./views/app/bricks/ocr/OcrFinancialParser.vue"),
          },
          {
            path: "ocr-receipt",
            component: () => import("./views/app/bricks/ocr/OcrFinancialParser.vue"),
          },
          {
            path: "ocr-financial",
            component: () => import("./views/app/bricks/ocr/OcrFinancialParser.vue"),
          },

          {
            path: "resume-parser",
            component: () => import("./views/app/bricks/ocr/OcrCv.vue"),
          },
          {
            path: "async-ocr-tables",
            component: () => import("./views/app/bricks/ocr/AsyncOcrTables.vue"),
          },
          {
            path: "ocr-id",
            component: () => import("./views/app/bricks/ocr/OcrId.vue"),
          },
          {
            path: "bank-check-parsing",
            component: () => import("./views/app/bricks/ocr/BankCheckParsing.vue"),
          },
          {
            path: "data-extraction",
            component: () => import("./views/app/bricks/ocr/DataExtraction.vue"),
          },
          {
            path: "custom-document-parser",
            component: () => import("./views/app/bricks/ocr/CustomDocumentParsing.vue"),
          },
          {
            path: "ocr-async",
            component: () => import("./views/app/bricks/ocr/OcrAsync.vue"),
          },
          {
            path: "document-redaction",
            component: () => import("./views/app/bricks/ocr/DocumentRedaction.vue"),
          },
          {
            path: "invoice-splitter",
            component: () => import("./views/app/bricks/ocr/InvoiceSplitterAsync.vue"),
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "translation",
        component: () => import("./views/app/bricks/translation"),
        redirect: `${bricksRoot}/translation/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/translation/Default"),
          },
          {
            path: "translate",
            component: () => import("./views/app/bricks/translation/Translation.vue"),
          },
          {
            path: "document-translation",
            component: () => import("./views/app/bricks/translation/DocumentTranslation.vue"),
          },
          {
            path: "language-detection",
            component: () => import("./views/app/bricks/translation/LanguageDetection.vue"),
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "generative",
        component: () => import("./views/app/bricks/generative"),
        redirect: `${bricksRoot}/generative/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/generative/Default"),
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "custom",
        component: () => import("./views/app/bricks/custom"),
        redirect: `${bricksRoot}/custom/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/custom/Default"),
          },
        ],
      },
      {
        path: "vision",
        component: () => import("./views/app/bricks/vision"),
        redirect: `${bricksRoot}/vision/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/vision/Default"),
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "speech",
        component: () => import("./views/app/bricks/speech"),
        redirect: `${bricksRoot}/speech/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/speech/Default"),
          },
          {
            path: "speech-to-text",
            component: () => import("./views/app/bricks/speech/SpeechToText.vue"),
          },
          {
            path: "text-to-speech",
            component: () => import("./views/app/bricks//speech/TextToSpeech.vue"),
          },
          {
            path: "asynchronous-speech-to-text",
            component: () => import("./views/app/bricks/speech/AsynchronousSpeechToText.vue"),
          },
          {
            path: "asynchronous-text-to-speech",
            component: () => import("./views/app/bricks/speech/AsynchronousTextToSpeech.vue"),
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "edenai-products",
        component: () => import("./views/app/bricks/edenai-products"),
        redirect: `${bricksRoot}/edenai-products/default`,
        children: [
          {
            path: "default",
            component: () => import("./views/app/bricks/edenai-products/Default"),
          },
          {
            path: "askyoda",
            component: () => import("./views/app/bricks/edenai-products/yoda"),
            redirect: `${bricksRoot}/edenai-products/askyoda/default`,

            children: [
              {
                path: "default",
                component: () => import("./views/app/bricks/edenai-products/yoda/Default.vue"),
              },
              {
                name: "askyoda-project",
                path: "project/:id",
                component: () => import("./views/app/bricks/edenai-products/yoda/Project.vue"),
              },
            ],
          },
          {
            path: "translathor",
            component: () => import("./views/app/bricks/edenai-products/translathor"),
            redirect: `${bricksRoot}/edenai-products/translathor/default`,

            children: [
              {
                path: "default",
                component: () =>
                  import("./views/app/bricks/edenai-products/translathor/Default.vue"),
              },
              {
                name: "translathor-project",
                path: "project/:name",
                component: () =>
                  import("./views/app/bricks/edenai-products/translathor/Project.vue"),
              },
            ],
          },
          {
            path: "x-merge",
            component: () => import("./views/app/bricks/edenai-products/xmerge"),
            redirect: `${bricksRoot}/edenai-products/x-merge/default`,
            children: [
              {
                path: "default",
                component: () => import("./views/app/bricks/edenai-products/xmerge/Default.vue"),
              },
              {
                path: "project/:name",
                component: () => import("./views/app/bricks/edenai-products/xmerge/Project.vue"),
              },
            ],
          },
          {
            path: "prompts",
            name: "prompts",
            component: () => import("./views/app/bricks/edenai-products/prompt"),
            children: [
              {
                path: ":name",
                name: "detail",
                component: () =>
                  import("./views/app/bricks/edenai-products/prompt/PromptDetail.vue"),
              },
            ],
          },
          {
            path: "workflows",
            component: () => import("./views/app/bricks/edenai-products/workflows"),
            redirect: `${bricksRoot}/edenai-products/workflows/default`,

            children: [
              {
                path: "default",
                component: () => import("./views/app/bricks/edenai-products/workflows/Default.vue"),
              },
            ],
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: "dataset",
        component: () => import("./views/app/bricks/Dataset"),
        // meta: { roles: [UserRole.Admin] },
      },
      // {
      //   path: "automatic-benchmark",
      //   component: () => import("./views/app/bricks/AutoBenchmark"),
      // },
    ],
    meta: { authOnly: true },
  },
  {
    path: "/integrations",
    component: () => import(/* webpackChunkName: "user" */ "./views/app"),
    redirect: "/integrations/all-connectors",
    children: [
      {
        path: "all-connectors",
        name: "all-connectors",
        component: () =>
          import(/* webpackChunkName: "user" */ "./views/app/integrations/Integrations"),
      },
    ],
    meta: { authOnly: true },
  },
  {
    path: `${adminRoot}`,
    component: () => import(/* webpackChunkName: "app" */ "./views/app"),
    redirect: `${adminRoot}`,
    children: [
      {
        path: `${adminRoot}/iam`,
        component: () => import("./views/app/administration/iam/index"),
        redirect: `${adminRoot}/iam/organization`,
        children: [
          {
            path: "profile",
            component: () => import("./views/app/administration/iam/ProfileDetails"),
          },
          {
            path: "organization",
            name: "organization",
            component: () => import("./views/app/administration/iam/Organization"),
            meta: { orgaAdminOnly: true },
          },
        ],
      },
      {
        path: `${adminRoot}/billing`,
        component: () => import("./views/app/administration/billing/index"),
        redirect: `${adminRoot}/billing/buy-credits`,
        children: [
          {
            path: "buy-credits",
            component: () => import("./views/app/administration/billing/BuyCredit"),
          },
          {
            path: "plan",
            name: "upgrade-plan",
            component: () => import("./views/app/administration/billing/UpgradePlan"),
          },
        ],
      },
      {
        path: `${adminRoot}/api-settings`,
        component: () => import("./views/app/administration/api-settings/index"),
        redirect: `${adminRoot}/api-settings/features-preferences`,
        children: [
          {
            path: "features-preferences",
            component: () => import("./views/app/administration/api-settings/ApiSettings"),
          },
          {
            path: "ai-api-keys",
            component: () => import("./views/app/administration/api-settings/OwnApiKeys"),
          },
        ],
        meta: { authOnly: true },
      },
      {
        path: `${adminRoot}/impersonation`,
        component: () => import("./views/app/administration/impersonation/index"),
        redirect: `${adminRoot}/impersonation/impersonate`,
        children: [
          {
            path: "impersonate",
            component: () => import("./views/app/administration/impersonation/Impersonate"),
          },
        ],
        meta: { adminOnly: true },
      },
    ],
    meta: { authOnly: true },
  },
  {
    path: "/usage",
    component: () => import(/* webpackChunkName: "app" */ "./views/app/cost-and-usage"),
    redirect: "/usage/monitoring",
    children: [
      {
        path: "batch",
        component: () => import("./views/app/cost-and-usage/Batch"),
      },
      {
        path: "history",
        component: () => import("./views/app/cost-and-usage/History"),
        name: "history",
      },
      {
        path: "monitoring",
        component: () => import("./views/app/cost-and-usage/CostMonitoring"),
      },
      {
        path: "observability",
        component: () => import("./views/app/cost-and-usage/Observability"),
      },
    ],
    meta: { authOnly: true },
  },
  {
    path: "/user",
    component: () => import(/* webpackChunkName: "user" */ "./views/user"),
    redirect: "/user/login",
    children: [
      {
        path: "login",
        name: "login",
        component: () => import(/* webpackChunkName: "user" */ "./views/user/Login"),
      },
      {
        path: "register",
        name: "register",
        component: () => import(/* webpackChunkName: "user" */ "./views/user/Register"),
      },
      {
        path: "forgot-password",
        component: () => import(/* webpackChunkName: "user" */ "./views/user/ForgotPassword"),
      },
    ],
    meta: { guestOnly: true },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackChunkName: "user" */ "./views/user/Logout"),
  },
  {
    path: "/onboarding",
    component: () => import(/* webpackChunkName: "user" */ "./views/app/onboarding"),
    name: "onboarding",
    redirect: "/onboarding/1",
    meta: { authOnly: true },
    children: [
      {
        name: "onboarding-1",
        path: "/onboarding/1",
        component: () => import(/* webpackChunkName: "user" */ "./views/app/onboarding/FirstStep"),
        beforeEnter: (to, from, next) => {
          if (store.getters["auth/canPassOnboardingFirstStep"]) {
            next({ name: "onboarding-2" });
          }
          next();
        },
      },
      {
        name: "onboarding-2",
        path: "/onboarding/2",
        component: () => import(/* webpackChunkName: "user" */ "./views/app/onboarding/SecondStep"),
      },
    ],
  },
  {
    path: "/aws-onboarding",
    name: "aws-onboarding",
    component: () => import(/* webpackChunkName: "user" */ "./views/user/AWSOnboarding"),
  },
  {
    path: "*",
    name: "not_found",
    component: () => import(/* webpackChunkName: "error" */ "./views/Error"),
  },
  {
    path: "*",
    name: "maintenance",
    component: () => import(/* webpackChunkName: "error" */ "./views/Maintenance"),
  },
];

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
  mode: "history",
});

pause(); // wait for user auth to be set
//router.beforeEach(AuthGuard);

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters["auth/authenticated"]) {
      next({
        path: "/user/login",
        query: to.query,
      });
    } else {
      await pausedPromise;

      // redirect to onboarding if never filled
      if (!to.name?.includes("onboarding") && !store.state.auth.authuser.popup_filled) {
        next({ name: "onboarding" });
      }

      if (store.getters["auth/isDeactivatedMember"]) {
        store.dispatch("auth/signOut");
        window.location = "/user/login?out=true";
      } else if (to.matched.some((record) => record.meta.memberForbid)) {
        if (store.getters["auth/memberUser"]) {
          next({
            name: "not_found",
          });
        } else {
          next();
        }
      } else if (to.matched.some((record) => record.meta.orgaAdminOnly)) {
        if (store.getters["auth/adminOrgaUser"]) {
          next();
        } else {
          next({
            name: "not_found",
          });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guestOnly)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters["auth/authenticated"]) {
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
