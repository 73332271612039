import axios from "axios";
import router from "../../router";
import url from "../../aimp/constants";
import { resume } from "../../../src/router";

axios.defaults.baseURL = url;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.code === "ECONNABORTED") {
      return router.push({ name: "maintenance" });
    }
    if ([502, 503, 504].includes(error.response?.status)) {
      return router.push({ name: "maintenance" });
    }

    if (error.response?.status === 401 && originalRequest.url === "token") {
      return Promise.reject(error);
    }

    if (
      (error.response?.status === 401 && originalRequest.url === "token/refresh") ||
      (error.response?.status === 401 && originalRequest._retry)
    ) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("frontApiKey");
      localStorage.removeItem("apiKey");
      window.location.reload();
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post("token/refresh", {
          refresh: localStorage.getItem("refreshToken"),
        })
        .then((res) => {
          if (res.status === 200) {
            // 1) set the new token in state
            localStorage.setItem("accessToken", res.data.access);
            // 2) Update default headers
            axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
              "accessToken"
            )}`;
            // 3) return originalRequest object with Axios.
            originalRequest.headers["Authorization"] = `Bearer ${localStorage.getItem(
              "accessToken"
            )}`;
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);

export default {
  namespaced: true,
  state: {
    accessToken: null,
    refreshToken: null,
    frontApiKey: null,
    apiKey: null,
    sandboxApiKey: null,
    authuser: {
      fullname: "",
      email: "",
      ai_credit: "",
      popup_filled: false,
      eu_only: false,
      got_50_credits: false,
      country: "",
      job_title: "",
      is_organisation_member: false,
      is_organisation_admin: false,
      popup_programming_language: "",
      popup_project_type: "",
      feature_access: { cache: false },
      plan: 0,
      get_plan_display: "Learning",
      cache_activated: false,
      total_credits_bought: 0,
      cancel_subscription: false,
      save_history: false,
      organisation_info: {
        organisation_name: "",
        organisation_id: "",
      },
      is_superuser: false,
      old_onboarding_done: true,
      company_website: null,
      company_name: null,
    },
    errors: {
      fullname: [""],
      email: [""],
      password: [""],
      job_title: [""],
      country: [""],
      popup_filled: [""],
      popup_programming_language: [""],
    },
    success: "",
    editPasswordSuccess: "",
    editPasswordErrors: {
      confirm_password: [""],
      current_password: [""],
      password: [""],
    },
  },

  actions: {
    async signIn({ dispatch }, credentials) {
      let response = await axios.post("token", {
        username: credentials.email,
        password: credentials.password,
      });
      return dispatch("attempt", response.data);
    },

    async attempt({ commit, state }, response) {
      if (response.access && response.refresh) {
        commit("SET_ACCESS", response.access);
        commit("SET_REFRESH", response.refresh);
      }

      if (!state.accessToken || !state.refreshToken) {
        return;
      }

      try {
        let userURL = "user/account/user";
        // if aws customer id is set in local storage, we add it as a
        // quey param to request to be able to connect aws account to user
        // then remove from local storage so that it is used only once
        const awsCustomerPublicID = window.localStorage.getItem("aws_customer");
        const awsProductCode = window.localStorage.getItem("aws_product_code");
        if (awsCustomerPublicID) {
          userURL = userURL.concat(
            `?aws_customer=${awsCustomerPublicID}&product_code=${awsProductCode}`
          );
        }
        const res = await axios.get(userURL, { timeout: 10000 });

        //pas tout le authuser
        if (res.data.is_confirmed === true) {
          commit("SET_AUTHUSER", res.data);
          commit("SET_FRONTAPIKEY", res.data.front_api_token);
          commit("SET_APIKEY", res.data.api_token);
          commit("SET_SANDBOX_APIKEY", res.data.sandbox_api_token);
          window.localStorage.removeItem("aws_customer");
          window.localStorage.removeItem("aws_product_code");
          resume(); // alert router that auth has been set
        } else {
          return router.push("/user/login");
        }
      } catch (e) {
        //commit('SET_ACCESS', null),
        //commit('SET_REFRESH', null)
        //commit('SET_AUTHUSER', null)
        //commit ('SET_FRONTAPIKEY', null)
      }
    },

    signOut({ commit }) {
      commit("SET_ACCESS", null);
      commit("SET_REFRESH", null);
      commit("SET_AUTHUSER", null);
      commit("SET_FRONTAPIKEY", null);
      commit("SET_APIKEY", null);
      commit("SET_SANDBOX_APIKEY", null);
    },

    async editProfile({ commit }, formAccount) {
      try {
        const data = new FormData();
        const key = ["fullname", "email", "eu_only"];
        const value = [formAccount.fullname, formAccount.email, formAccount.eu_only];

        if (formAccount.image) {
          key.push("image");
          value.push(formAccount.image);
        }
        for (let i = 0; i < value.length; i++) {
          data.append(key[i], value[i]);
        }

        let res = await axios.put("user/account/user", data);
        commit("SET_AUTHUSER", res.data);
        commit("DELETE_ERRORS");
        commit("SET_SUCCESS");
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
      }
    },

    async editPassword({ commit }, formEditPassword) {
      try {
        const data = new FormData();
        const key = ["current_password", "password", "confirm_password"];
        const value = [
          formEditPassword.current_password,
          formEditPassword.password,
          formEditPassword.confirm_password,
        ];

        for (let i = 0; i < value.length; i++) {
          data.append(key[i], value[i]);
        }

        let response = await axios.put("user/update_password", data);
        commit("DELETE_EDITPASSWORDERRORS");
        commit("SET_EDITPASSWORDSUCCESS");
      } catch (e) {
        commit("DELETE_EDITPASSWORDERRORS");
        commit("SET_EDITPASSWORDERRORS", e.response.data.errors);
      }
    },

    updateOnboardingFilled: ({ commit }) => {
      commit("SET_ONBOARDING_FILLED", true);
    },
  },

  mutations: {
    SET_ACCESS(state, access) {
      state.accessToken = access;
    },
    SET_REFRESH(state, refresh) {
      state.refreshToken = refresh;
    },
    SET_AUTHUSER(state, data) {
      if (data === null) {
        state.authuser.fullname = null;
        state.authuser.email = null;
        state.authuser.country = null;
        state.authuser.job_title = null;
        state.authuser.ai_credit = null;
        state.authuser.popup_filled = null;
        state.authuser.got_50_credits = null;
        state.authuser.eu_only = null;
        state.authuser.is_organisation_member = null;
        state.authuser.is_organisation_admin = null;
        state.authuser.popup_programming_language = null;
        state.authuser.popup_project_type = null;
        state.authuser.plan = 0;
        state.authuser.get_plan_display = null;
        state.authuser.feature_access = { cache: false };
        state.authuser.cache_activated = false;
        state.authuser.total_credits_bought = 0;
        state.authuser.cancel_subscription = false;
        state.authuser.save_history = false;
        state.authuser.organisation_info.organisation_name = null;
        state.authuser.organisation_info.organisation_id = null;
        state.authuser.is_superuser = false;
        state.authuser.company_website = null;
        state.authuser.old_onboarding_done = true;
        state.authuser.company_name = null;
      } else {
        state.authuser.fullname = data.fullname;
        state.authuser.email = data.email;
        state.authuser.job_title = data.job_title;
        state.authuser.country = data.country;
        state.authuser.ai_credit = null;
        state.authuser.ai_credit = data.credits.toString();
        state.authuser.popup_filled = data.popup_filled;
        state.authuser.got_50_credits = data.got_50_credits;
        state.authuser.eu_only = data.eu_only;
        state.authuser.is_organisation_member = data.is_organisation_member;
        state.authuser.is_organisation_admin = data.is_organisation_admin;
        state.authuser.popup_programming_language = data.popup_programming_language;
        state.authuser.popup_project_type = data.popup_project_type;
        state.authuser.plan = data.plan;
        state.authuser.get_plan_display = data.get_plan_display;
        state.authuser.feature_access = data.feature_access;
        state.authuser.cache_activated = data.cache_activated;
        state.authuser.total_credits_bought = data.total_credits_bought;
        state.authuser.cancel_subscription = data.cancel_subscription;
        state.authuser.save_history = data.save_history;
        state.authuser.organisation_info.organisation_name =
          data.organisation_info.organisation_name;
        state.authuser.organisation_info.organisation_id = data.organisation_info.organisation_id;
        state.authuser.is_superuser = data.is_superuser;
        state.authuser.old_onboarding_done = data.old_onboarding_done;
        state.authuser.company_website = data.company_website;
        state.authuser.company_name = data.company_name;
      }
    },
    SET_FRONTAPIKEY(state, data) {
      state.frontApiKey = data;
    },

    SET_APIKEY(state, data) {
      state.apiKey = data;
    },

    SET_SANDBOX_APIKEY(state, data) {
      state.sandboxApiKey = data;
    },

    SET_ERRORS(state, errors) {
      state.success = "";
      for (const key in errors) {
        state.errors[key] = errors[key];
      }
    },

    SET_EDITPASSWORDERRORS(state, errors) {
      state.editPasswordSuccess = "";
      for (const key in errors) {
        state.editPasswordErrors[key] = errors[key];
      }
    },

    SET_SUCCESS(state) {
      state.success = "User updated.";
    },

    SET_EDITPASSWORDSUCCESS(state) {
      state.editPasswordSuccess = "Password updated.";
    },

    DELETE_ERRORS(state) {
      (state.errors.fullname = [""]),
        (state.errors.email = [""]),
        (state.errors.popup_filled = [""]);
      state.errors.got_50_credits = [""];
    },

    DELETE_EDITPASSWORDERRORS(state) {
      (state.editPasswordErrors.confirm_password = [""]),
        (state.editPasswordErrors.current_password = [""]),
        (state.editPasswordErrors.password = [""]);
    },

    SET_ONBOARDING_FILLED: (state) => {
      state.authuser.popup_filled = true;
    },
  },

  getters: {
    authenticated(state) {
      return state.accessToken && state.refreshToken && state.authuser;
    },
    isDeactivatedMember(state) {
      return (
        state.authuser.is_organisation_member && !state.authuser.feature_access.organization_feature
      );
    },
    memberUser(state) {
      return state.authuser.is_organisation_member;
    },
    adminOrgaUser(state) {
      return state.authuser.is_organisation_admin;
    },
    authuser(state) {
      return state.authuser;
    },
    frontApiKey(state) {
      return state.frontApiKey;
    },
    apiKey(state) {
      return state.apiKey;
    },
    sandboxApiKey(state) {
      return state.sandboxApiKey;
    },
    errors(state) {
      return state.errors;
    },
    editPasswordErrors(state) {
      return state.editPasswordErrors;
    },
    success(state) {
      return state.success;
    },
    editPasswordSuccess(state) {
      return state.editPasswordSuccess;
    },
    canPassOnboardingFirstStep(state) {
      // if company name is set, that means he already completed first step before and can pass it
      // if website match email, we will get data from apollo in back, so user don't need to fill 1st step
      // if org member, his admin already filled first step
      const { email, company_website, is_organization_member, company_name } = state.authuser;
      const emailDomain = email.split("@").at(-1);
      const websiteEmailMatch = company_website?.includes(emailDomain);
      return websiteEmailMatch || is_organization_member || company_name;
    },
    isSuperuser(state) {
      return state.authuser.is_superuser;
    },
  },
};
